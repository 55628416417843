import Kosten from "./kosten";

export default class Telefonat extends Kosten {
    constructor(id, empfaenger, einheiten, datum, kosten){
        super(id, 'Telefonat', '', datum, kosten);
        this.empfaenger = empfaenger
        this.einheiten = einheiten
    }

    toFirestore(){
        return Object.assign(super.toFirestore(), {
            empfanger: this.empfaenger,
            einheiten: this.einheiten
        })
    }
}