import Adresse from "./adresse";

class Ort extends Adresse {
    constructor(id, additionalName, strasse, nr, plz, ort, beschreibung, haeufigkeit = 1, type){
        super(strasse, nr, plz, ort, additionalName)
        this.id = id
        this.haeufigkeit = haeufigkeit || 1
        this.beschreibung = beschreibung
        this.type = type || 'Allgemein'
    }

    getAdresse(){
        return new Adresse(this.strasse, this.nr, this.plz, this.ort, this.additionalName)
    }

    toFirestore(){
        return {
            id: this.id,
            additionalName: this.additionalName || '',
            strasse: this.strasse || '',
            nr: this.nr || '',
            plz: this.plz || '',
            ort: this.ort || '',
            beschreibung: this.beschreibung || '',
            haeufigkeit: this.haeufigkeit
        }
    }
}

export default Ort