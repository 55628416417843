import md5 from "md5"

export class Ausschuss {

    constructor(id, name, funktion, eigene_genehmigung, genehmigende_personen = []){
        this.id = id || md5(name)
        this.name = name
        this.funktion = funktion
        this.eigene_genehmigung = eigene_genehmigung || false
        this.genehmigende_personen = genehmigende_personen
    }

    toFirestore(){
        return {
            id: this.id,
            name: this.name,
            funktion: this.funktion,
            eigene_genehmigung: this.eigene_genehmigung,
            genehmigende_personen: this.genehmigende_personen
        }
    }

}