import Abrechnung from "./abrechnung";
import Reisekostenabrechnung from "./reisekostenabrechnung";

const abrechnungConverter = {
    toFirestore: (abrechnung) => {
        return abrechnung.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        if(data.type == 'Reisekostenabrechnung'){
            return new Reisekostenabrechnung(
                snapshot.id,
                data.versionId,
                data.empfaenger,
                data.abrechnender,
                data.reisezweck,
                data.reise_angeordnet,
                data.reisebeginn,
                data.reiseende,
                data.reisestart,
                data.reiseziel,
                data.wegpunkte,
                data.routetype,
                data.fahrtkosten,
                data.nebenkosten,
                data.auslagen,
                data.uebernachtungsgelder,
                data.status,
                data.freigaben,
                data.historie,
                data.anmerkungen,
                data.kostenstelle,
                data.kostentraeger,
                data.printed,
                data.kreditorennummer,
                data.tags
            )
        }
        return new Abrechnung(
            snapshot.id,
            data.versionId,
            data.empfaenger,
            data.abrechnender,
            data.reisekosten,
            data.portokosten,
            data.telefonkosten,
            data.sonstige_kosten,
            data.status,
            data.freigaben,
            data.zeitraum,
            data.type,
            data.historie,
            data.anmerkungen,
            data.kostenstelle,
            data.kostentraeger,
            data.printed,
            data.kreditorennummer,
            data.tags
        )
    }
}

export default abrechnungConverter