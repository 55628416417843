<template>
    <v-app-bar
      app
      flat
      color="background"
      clipped-left
      height="80"
      elevate-on-scroll
      elevation="10"
      style="height: calc(80 + env(safe-area-inset-top)); padding-top: calc(5px + env(safe-area-inset-top));"
    >
      <v-btn v-if="user.data" icon x-large @click="val = !val">
        <font-awesome-icon icon="fa-light fa-bars" size="lg" />
      </v-btn>
      <v-avatar size="60px" :color="$route.meta.kreiss ? 'white' : ''" class="mx-3" :class="$route.meta.kreiss ? 'pa-1' : ''">
        <v-img v-if="!$route.meta.kreiss" src="/logos/flvw_logo.webp" contain></v-img>
        <v-img v-else :src="`/logos/kreise/${user.data.customFields.kreis}-round.png`" contain></v-img>
      </v-avatar>
      <div><h2>Abrechnung</h2></div>
      <v-spacer></v-spacer>
      <v-btn v-if="!user.data && $route.name != 'Login'" color="primary" to="/login">
        <font-awesome-icon class="mr-2" icon="fa-light fa-lock-keyhole" size="lg" /> Login
      </v-btn>
      <!--<v-select v-if="user.data && user.data.rollen.length > 1 && $vuetify.breakpoint.mdAndUp" :items="user.data.rollen" v-model="user.data.rolle" label="Rolle ändern" style="max-width: 250px;"></v-select>-->
      <v-menu offset-y v-if="user.data" min-width="150">
        <template v-slot:activator="{ on, attrs }">
                <keep-alive><h3 v-if="$vuetify.breakpoint.mdAndUp" class="ml-2" v-bind="attrs" v-on="on">{{user.data.displayName}}</h3></keep-alive>
                <v-avatar v-bind="attrs" v-on="on">
                    <v-img :src="user.data.photoUrl" contain></v-img>
                </v-avatar>
        </template>
        <v-list>
            <v-list-item>
              <v-switch v-model="$vuetify.theme.dark" @change="saveDarkmode()" :label="$vuetify.theme.dark ? 'Dark Mode an' : 'Dark Mode aus'" class="mb-3"></v-switch>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item to="/mein-profil">
              <v-list-item-title>Mein Profil</v-list-item-title>
            </v-list-item>
            <v-list-item to="/email-benachrichtigungen">
              <v-list-item-title>E-Mail Benachrichtigungen</v-list-item-title>
            </v-list-item>
            <v-divider></v-divider>
            <v-list-item @click="logout()">
            <v-list-item-title>Logout</v-list-item-title>
            </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>
</template>

<script>
import { mapGetters } from 'vuex'
import { logout } from '@/auth/auth'

export default {
  name: 'Header',
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    val: {
        get() {
            return this.value
        },
        set(val) {
            this.$emit('input', val)
        }
    },
  },
  props: {
    value: {
        type: Boolean,
        required: true
    }
  },
  watch: {
    value() {
        this.$emit('input', this.value)
    }
  },
  methods: {
    saveDarkmode(){
      this.user.data.darkmode = this.$vuetify.theme.dark
      this.user.data.saveUser()
    },  
    logout(){
        logout()
    }
  },
  data: () => ({
    //
  }),
};
</script>