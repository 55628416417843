import Kreis from '@/classes/Kreise/kreis'
import KreisCollection from '@/classes/Kreise/kreisCollection'
import { getDocs, getFirestore, collection } from '@firebase/firestore'

const KreiseModule = {
  state: {
    kreise: new KreisCollection()
  },
  getters: {
    kreise(state) {
      return state.kreise
    },
  },
  mutations: {
    ADD_KREIS(state, data) {
      state.kreise.hinzufuegen(new Kreis(data.nummer, data.name, data.titel, data.kurz, data.bereiche))
    },
  },
  actions: {
    async loadKreise({ commit }) {
      const kreise = await getDocs(collection(getFirestore(), 'Kreis'))

      kreise.forEach(kreis => {
        commit('ADD_KREIS', {
            nummer: parseInt(kreis.data().nummer.toString().padStart(2, '0')),
            kurz: kreis.data().name.replace('KREIS ', ''),
            name: 'KREIS '+kreis.data().nummer.toString().padStart(2, '0') + ' ' + kreis.data().name.replace('KREIS ', ''),
            titel: kreis.data().name,
            bereiche: kreis.data().bereiche
        })
      })
    },
  },
}

export default KreiseModule
