<template>
  <v-container fluid>
    <v-overlay class="bg-primary-gradient text-center">
      <v-row justify="center">
        <v-col cols="auto">
          <v-img
            src="/logos/flvw_logo.png"
            :max-width="$vuetify.breakpoint.mdAndUp ? '10vw' : '25vw'"
          ></v-img>
        </v-col>
      </v-row>
      <h1 class="my-5">
        Abrechnungs-App</h1>
      <v-progress-linear
        indeterminate
        color="white"
      ></v-progress-linear>
    </v-overlay>
  </v-container>
</template>

<script>
export default {
  name: 'loader',
}
</script>

<style scoped>

</style>
