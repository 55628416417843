import Abrechnung from '@/classes/Abrechnung/abrechnung'

const AbrechnungModule = {
  state: {
    auslagenabrechnung: new Abrechnung(),
  },
  getters: {
    auslagenabrechnung(state) {
      return state.auslagenabrechnung
    },
  },
  
  mutations: {
    NEUE_AUSLAGENABRECHNUNG(state) {
      state.auslagenabrechnung = new Abrechnung()
    },
  },
  actions: {
    async newAbrechnung({ commit }) {
        commit('NEUE_AUSLAGENABRECHNUNG')
    },
  },
}

export default AbrechnungModule
