import Vue from 'vue'
import Vuex from 'vuex'
import './firebaseConfig'
import UserModule from './store/user'
import KreisModule from './store/kreis'
import LandesverbandModule from './store/landesverband'
import AbrechnungModule from './store/abrechnung'

Vue.use(Vuex)
const store = new Vuex.Store()

store.registerModule('UserModule', UserModule)
store.registerModule('KreisModule', KreisModule)
store.registerModule('LandesverbandModule', LandesverbandModule)
store.registerModule('AbrechnungModule', AbrechnungModule)

export default store
