import md5 from "md5";

export default class Kosten {
    constructor(id, type, name, datum, kosten){
        this.id = id || md5(Date.now());
        this.type = type || 'Kosten'
        this.name = name;
        this.datum = datum;
        this.kosten = parseFloat(kosten).toFixed(2);
    }

    parseDatum(){
        if(this.datum){
            const datum = this.datum.split('-')
            return datum[2]+'.'+datum[1]+'.'+datum[0]
        }
        return ''
    }

    toFirestore(){
        return {
            id: this.id,
            type: this.type,
            name: this.name || '',
            datum: this.datum || '',
            kosten: parseFloat(this.kosten).toFixed(2)
        }
    }
}