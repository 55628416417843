import Kosten from "./kosten";

export default class Telefonpauschale extends Kosten {
    constructor(id, name, datum, kosten, zeitraum){
        super(id, 'Telefonpauschale', name, datum, kosten);
        this.zeitraum = zeitraum
    }

    toFirestore(){
        return Object.assign(super.toFirestore(), {
            zeitraum: this.zeitraum || ''
        })
    }
}