import { doc, getFirestore, updateDoc } from "firebase/firestore"
import { Ausschuss } from "./ausschuss"

class Kreis {
    constructor(
        id,
        name,
        titel,
        kurz,
        ausschuesse = []
    ) {
        this.id = id
        this.nummer = id
        this.name = name
        this.titel = titel
        this.kurz = kurz
        this.ausschuesse = ausschuesse.map(item => {
            return new Ausschuss(item.id, item.name, item.funktion, item.eigene_genehmigung, item.genehmigende_personen)
        })
    }

    async saveKreis(){
        await updateDoc(doc(getFirestore(), '/Kreis/'+this.id), this.toFirestore()).catch(error => {
            console.log(error)
        })
    }

    sucheAusschuss(name, id) {
        return this.ausschuesse.find(item => item.name == name || item.id == id)
    }

    toFirestore(){
        return {
            id: this.id,
            nummer: this.nummer,
            name: this.titel,
            kurz: this.kurz,
            bereiche: this.ausschuesse.map(item => {
                return item.toFirestore()
            })
        }
    }
}

export default Kreis