/* MODULES */
import Vue from 'vue'
import Router from 'vue-router'
import auth from '../auth/auth'
import store from '@/store'

/* COMPONENTS */
const index = () => import('../views/index.vue')

const usersites = () => import('../views/user/index.vue')

const Login = () => import('@/views/auth/login.vue')
const Dashboard = () => import('@/views/user/dashboard/dashboard.vue')
const Profil = () => import('@/views/user/mein-profil/profil.vue')
const MailBenachrichtigungen = () => import('@/views/user/mein-profil/email-benachrichtigungen.vue')
const ProfilVervollstaendigen = () => import('@/views/user/mein-profil/profil-vervollstaendigen.vue')

const AbrechnungErstellen = () => import('@/views/user/abrechnung/abrechnung-erstellen.vue')
const Abrechnung = () => import('@/views/user/abrechnung/abrechnung.vue')
const AbrechnungenUebersicht = () => import('@/views/user/abrechnung/abrechnungen-uebersicht.vue')
const FLVWAbrechnungenUebersicht = () => import('@/views/user/abrechnung/flvw-uebersicht.vue')
const LVAbrechnungenUebersicht = () => import('@/views/user/abrechnung/abteilung-uebersicht.vue')
const KreisAbrechnungenUebersicht = () => import('@/views/user/abrechnung/kreis-uebersicht.vue')

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: index,
    meta: {
      requiresAuth: false,
    },
    children: [
      {
        path: '/impressum',
        name: 'Impressum',
        component: () => import('@/views/rechtliches/impressum.vue'),
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-light fa-info-circle'
        },
      },
      {
        path: '/datenschutz',
        name: 'Datenschutz',
        component: () => import('@/views/rechtliches/datenschutz.vue'),
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-light fa-info-circle'
        },
      },
      {
        path: '/changelog',
        name: 'Changelog',
        component: () => import('@/views/support/changelog.vue'),
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-light fa-code-branch'
        },
      },
      {
        path: '/support',
        name: 'Support',
        component: () => import('@/views/support/index.vue'),
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-brands fa-github'
        },
      },
      {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-light fa-lock-keyhole'
        },
        // beforeEnter: (to, from, next) => {
        //   if (auth.getUser()) {
        //     next('dashboard')
        //   } else {
        //     next()
        //   }
        // },
      },
      {
        path: '/event/:id',
        name: 'Digitale Anwesenheitsliste',
        component: () => import('@/views/event/index.vue'),
        meta: {
          requiresAuth: false,
          allowUnverified: true,
          icon: 'fa-duotone fa-ballot-check',
          noBackToBtn: true
        },
      },
      {
        path: '/',
        component: usersites,
        children: [
          {
            path: '/',
            redirect: '/dashboard',
          },
          {
            path: '/dashboard',
            name: 'Dashboard',
            component: Dashboard,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-table-columns',
              noBackToBtn: true
            },
          },
          {
            path: '/mein-profil',
            name: 'Profil',
            component: Profil,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-table-columns'
            },
          },
          {
            path: '/email-benachrichtigungen',
            name: 'E-Mail Benachrichtigungen',
            component: MailBenachrichtigungen,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-table-columns'
            },
          },
          {
            path: '/profil-vervollstaendigen',
            name: 'Profil vervollständigen',
            component: ProfilVervollstaendigen,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-edit'
            },
          },
          {
            path: '/abrechnung-erstellen',
            name: 'Neue Abrechnung',
            component: AbrechnungErstellen,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-plus'
            }
          },
          {
            path: '/abrechnungen',
            name: 'Meine Abrechnungen',
            component: AbrechnungenUebersicht,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-files',
              backToLink: '/dashboard'
            }
          },
          {
            path: '/abrechnungen/kreis/:id',
            name: 'Abrechnung',
            component: Abrechnung,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-file-lines',
              backToLink: '/abrechnungen/kreis',
              kreis: true
            }
          },
          {
            path: '/abrechnungen/kreis',
            name: 'Kreis-Abrechnungen',
            component: () => import('@/views/user/abrechnung/kreis-uebersicht.vue'), //KreisAbrechnungenUebersicht,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-box-archive',
              backToLink: '/dashboard',
              kreis: true,
              hide_buttons: true,
              mode: 'Kreis'
            }
          },
          {
            path: '/abrechnungen/flvw/:id',
            name: 'Abrechnung',
            component: Abrechnung,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-file-lines',
              backToLink: '/abrechnungen/flvw'
            }
          },
          {
            path: '/abrechnungen/flvw',
            name: 'FLVW-Abrechnungen',
            component: () => import('@/views/user/abrechnung/flvw-uebersicht.vue'),
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-box-archive',
              backToLink: '/dashboard',
              hide_buttons: true,
              mode: 'FLVW'
            }
          },
          {
            path: '/abrechnungen/flvw-abteilung',
            name: 'Abteilungsabrechnungen',
            component: () => import('@/views/user/abrechnung/abteilung-uebersicht.vue'),//LVAbrechnungenUebersicht,
            meta: {
              requiresAuth: true,
              icon: 'fa-light fa-box-archive',
              backToLink: '/dashboard',
              hide_buttons: true,
              mode: 'FLVW'
            }
          },
          
          {
            path: '/pauschalen',
            name: 'Pauschalen',
            component: () => import('@/views/user/pauschalen/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Mitarbeiter'
              ],
              icon: 'fa-light fa-wallet'
            }
          },
          {
            path: '/meine-sitzungen',
            name: 'Meine Sitzungen',
            component: () => import('@/views/user/meine-sitzungen/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Mitarbeiter'
              ],
              icon: 'fa-light fa-calendar-star'
            }
          },
          {
            path: '/meine-vorlagen',
            name: 'Meine Vorlagen',
            component: () => import('@/views/user/vorlagen/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Mitarbeiter'
              ],
              icon: 'fa-light fa-file-edit'
            }
          },
          {
            path: '/meine-vorlagen/:vorlagenid',
            name: 'Vorlage verwenden',
            component: () => import('@/views/user/vorlagen/vorlage-verwenden.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Mitarbeiter'
              ],
              icon: 'fa-light fa-file-edit'
            }
          },
          {
            path: '/kreis/verwaltung',
            name: 'Kreisverwaltung',
            component: () => import('@/views/user/kreis/verwaltung/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Admin'
              ],
              icon: 'fa-light fa-cogs'
            }
          },
          {
            path: '/admin/abteilungen',
            name: 'Abteilungen',
            component: () => import('@/views/user/admin/abteilungen/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Finanzen',
                'Admin'
              ],
              icon: 'fa-light fa-file-edit'
            }
          },
          {
            path: '/admin/userverwaltung',
            name: 'Benutzerverwaltung',
            component: () => import('@/views/user/admin/userverwaltung/index.vue'),
            meta: {
              requiresAuth: true,
              rollen: [
                'Finanzen',
                'Admin'
              ],
              icon: 'fa-light fa-user-group'
            }
          },
          {
            path: '/anwesenheitslisten',
            component: () => import('@/views/user/anwesenheitslisten/index.vue'),
            children: [
              {
                path: '',
                name: 'Anwesenheitslisten',
                component: () => import('@/views/user/anwesenheitslisten/overview.vue'),
                meta: {
                  requiresAuth: true,
                  icon: 'fa-light fa-ballot-check',
                  noBackToBtn: true
                }
              },
              {
                path: 'erstellen',
                name: 'Veranstaltung erstellen',
                component: () => import('@/views/user/anwesenheitslisten/create.vue'),
                meta: {
                  requiresAuth: true,
                  icon: 'fa-light fa-calendar-plus',
                  backToLink: '/anwesenheitslisten'
                }
              },
              {
                path: ':id',
                name: 'Anwesenheitsliste',
                component: () => import('@/views/user/anwesenheitslisten/anwesenheitsliste.vue'),
                meta: {
                  requiresAuth: true,
                  icon: 'fa-light fa-ballot-check',
                  backToLink: '/anwesenheitslisten'
                }
              },
              {
                path: ':id/edit',
                name: 'Anwesenheitsliste',
                component: () => import('@/views/user/anwesenheitslisten/edit.vue'),
                meta: {
                  requiresAuth: true,
                  icon: 'fa-light fa-ballot-check',
                  backToLink: '/anwesenheitslisten/:id'
                }
              }
            ]
            
          },
          {
            path: '/gast-abrechnung',
            name: 'Gast-Abrechnung erstellen',
            component: () => import('@/views/public/gast-abrechnung.vue'),
            meta: {
              requiresAuth: false,
              icon: 'fa-light fa-file-invoice'
            }
          }
        ]
      }
    ],
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: routes,
})

router.afterEach((to, from) => {
  if(from){
    to.meta.backTo = from.fullPath
  }
  window.scrollTo(0,0)
})

export default router
