class Adresse {
    constructor(
        strasse,
        nr,
        plz,
        ort,
        additonialName
    ) {
        this.strasse = strasse
        this.nr = nr
        this.plz = plz
        this.ort = ort
        this.additionalName = additonialName || 'Adresse:'
    }

    getString(showName = false){
        if(showName && this.additionalName != 'Adresse:'){
            return this.additionalName+', '+this.strasse+' '+this.nr+', '+this.plz+' '+this.ort
        }
        return this.strasse+' '+this.nr+', '+this.plz+' '+this.ort
    }

    equal(adresse){
        if(adresse.strasse == this.strasse
            && adresse.nr == this.nr
            && adresse.plz == this.plz
            && adresse.ort == this.ort
        ) {
            return true
        }
        return false
    }

    toFirestore(){
        return Object.assign({}, {
            strasse: this.strasse || '',
            nr: this.nr || '',
            plz: this.plz || '',
            ort: this.ort || '',
            additionalName: this.additionalName || ''
        })
    }
}

export default Adresse