import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './routes/index'
import store from './store'
//import './registerServiceWorker'
import md5 from 'md5'
import VuetifyGoogleAutocomplete from 'vuetify-google-autocomplete';
import VueSignaturePad from 'vue-signature-pad';
import listAbrechnung from '@/components/abrechnung/list-abrechnung.vue';

import VueHtmlToPaper from 'vue-html-to-paper';

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
    'https://cdn.jsdelivr.net/npm/vuetify@2.x/dist/vuetify.min.css',
    'https://cdn.jsdelivr.net/npm/@mdi/font@6.x/css/materialdesignicons.min.css',
    'https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700,900'
  ]
}
Vue.use(VueHtmlToPaper, options);

Vue.use(VueSignaturePad);

Vue.component('list-abrechnung', listAbrechnung);

Vue.use(VuetifyGoogleAutocomplete, {
  apiKey: 'AIzaSyBvudrHGa-yj5DfS35Vo6dWPlmhqzaZ5b4', // Can also be an object. E.g, for Google Maps Premium API, pass `{ client: <YOUR-CLIENT-ID> }`
  installComponents: true, // Optional (default: true) - false, if you want to locally install components
  vueGoogleMapsCompatibility: false, // Optional (default: false) - true, requires vue2-google-maps to be configured see https://github.com/xkjyeah/vue-google-maps
});

Vue.config.productionTip = false
Vue.prototype.client = md5(process.env.VUE_APP_CLIENT_ID)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
