import md5 from "md5"
import { deleteObject, getBlob, getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { deleteDoc, doc, getFirestore, setDoc } from "firebase/firestore"

class Beleg {
    constructor(id, abrechnungId, filename, name, beschreibung, url, type){
        this.id = id || md5(Date.now())
        this.url = url
        this.type = type
        this.filename = filename
        this.name = name || filename ? filename.split('.')[0] : ''
        this.beschreibung = beschreibung
        this.abrechnungId = abrechnungId
        this.dataurl = ''
        this.fileDoesNotExist = false;
        if(url){
            this.downloadFileAndConvertToDataURL()
        }
    }

    async upload(beleg){
        // Upload File to Firebase Storage
        if(beleg instanceof File){
            const belegRef = ref(getStorage(), '/Belege/'+this.abrechnungId+'/'+this.id+'/'+beleg.name)
            uploadBytes(belegRef, beleg).then(snapshot => {
                getDownloadURL(belegRef).then(url => {
                    this.url = url
                    this.type = beleg.type
                    this.filename = snapshot.ref.name
                    this.save()
                })
            })
        }
    }

    async save(){
        await setDoc(doc(getFirestore(), '/Abrechnungen/'+this.abrechnungId+'/Belege/'+this.id), this.toFirestore())
    }

    async deleteBeleg(){
        const belegRef = ref(getStorage(), '/Belege/'+this.abrechnungId+'/'+this.id+'/'+this.filename)
        return deleteObject(belegRef).then(async () => {
            return deleteDoc(doc(getFirestore(), '/Abrechnungen/'+this.abrechnungId+'/Belege/'+this.id))
        })
    }

    async downloadFileAndConvertToDataURL() {
        if(this.type != 'application/pdf'){
            try {
                const response = await fetch(this.url);

                if (!response.ok) {
                    // Setzt this.dataurl auf einen leeren String, wenn ein HTTP-Fehler auftritt
                    this.dataurl = '';
                    this.fileDoesNotExist = true;
                    console.error('HTTP error:', response.status);
                    return; // Beendet die Funktion vorzeitig
                }

                const blob = await response.blob();
            
                // Convert blob to base64 data URL
                const reader = new FileReader();
                reader.onload = () => {
                    this.dataurl = reader.result;
                };
                reader.readAsDataURL(blob);
            } catch (error) {
                console.error('Error downloading or converting file:', error);
                this.dataurl = ''; 
                this.fileDoesNotExist = true;
            }
        }
    }
    

    toFirestore(){
        return Object.assign({}, {
            id: this.id,
            url: this.url,
            type: this.type,
            name: this.name || '',
            filename: this.filename || '',
            beschreibung: this.beschreibung || '',
            abrechnungId: this.abrechnungId
        })
    }
}

export default Beleg