import { getDocs, getFirestore, collection } from '@firebase/firestore'

const LandesverbandModule = {
  state: {
    hauptamt: [],
    ehrenamt: []
  },
  getters: {
    hauptamt(state) {
      return state.hauptamt
    },
    ehrenamt(state){
      return state.ehrenamt
    }
  },
  mutations: {
    RESET_HAUPTAMT(state){
      state.hauptamt = []
    },
    RESET_EHRENAMT(state){
      state.ehrenamt = []
    },
    ADD_HAUPTAMT(state, data) {
      state.hauptamt.push(data)
      state.hauptamt.sort((a, b) => a.name < b.name ? -1 : 1)
    },
    ADD_EHRENAMT(state, data) {
      state.ehrenamt.push(data)
      state.ehrenamt.sort((a, b) => a.name < b.name ? -1 : 1)
    },
  },
  actions: {
    async loadHauptamt({ commit }) {
      const hauptamt = await getDocs(collection(getFirestore(), 'Hauptamt'))
      commit('RESET_HAUPTAMT')
      hauptamt.forEach(amt => {
        commit('ADD_HAUPTAMT', {
          id: amt.id,
          name: amt.data().name,
          ansprechpartner: amt.data().ansprechpartner
        })
      })
    },
    async loadEhrenamt({ commit }) {
      const ehrenamt = await getDocs(collection(getFirestore(), 'Ehrenamt'))
      commit('RESET_EHRENAMT')
      ehrenamt.forEach(amt => {
        commit('ADD_EHRENAMT', {
          id: amt.id,
          name: amt.data().name,
          abteilungen: amt.data().abteilungen || []
        })
      })
    },
  },
}

export default LandesverbandModule
