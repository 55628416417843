import Auslage from './auslage'
import Person from './person'
import md5 from 'md5'
import User from '../User/user'
import Bank from '../User/bank'
import Reisekosten from './Reisekosten/reisekosten'
import Abrechnung from './abrechnung'
import Adresse from '../User/adresse'
import { collection, doc, getDoc, getDocs, getFirestore, onSnapshot, query, where } from 'firebase/firestore'
import Beleg from './Kosten/beleg'
import Tagegeld from './Reisekosten/tagegeldReisekosten'
import TagegeldTag from './Reisekosten/tagegeldTag'

class Reisekostenabrechnung extends Abrechnung {
    
    kilometersatz = 0.3

    constructor(
        id,
        versionId,
        empfaenger,
        abrechnender,
        reisezweck = '',
        reise_angeordnet = '',
        reisebeginn = {
            datum: '',
            uhrzeit: '17:00'
        },
        reiseende = {
            datum: '',
            uhrzeit: '19:00'
        },
        reisestart = new Adresse(),
        reiseziel = new Adresse(),
        wegpunkte = [],
        routetype = 'Start/Ziel',
        fahrtkosten = {
            pkw: {
                active: false,
                calculated: true,
                begruendung: '',
                kilometer: 0,
                summe: 0,
                kennzeichen: ''  
            },
            bundesbahn: {
                active: false,
                summe: 0,
            },
            flugzeug: {
                active: false,
                summe: 0,
            },
            zuschlaege: {
                active: false,
                summe: 0,
            },
            bus_taxi: {
                active: false,
                summe: 0
            }
        },
        nebenkosten = [],
        auslagen = {
            active: false,
            hauptamt: false,
            tage: 0,
            satz: 0,
            summe: 0
        },
        uebernachtungsgelder = {
            active: false,
            anzahl: 0,
            betrag: 0,
            summe: 0
        },
        status = 'Neu',
        freigaben = [],
        historie = [],
        anmerkungen = '',
        kostenstelle = [],
        kostentraeger = [],
        printed = false,
        kreditorennummer = '',
        tags = []
    ){
        
        super(id, versionId, empfaenger, abrechnender, [], [], [], [], status, freigaben, '', 'Reisekostenabrechnung', historie, anmerkungen, kostenstelle, kostentraeger, printed, kreditorennummer, tags)

        this.reisezweck = reisezweck
        this.reise_angeordnet = reise_angeordnet
        this.reisebeginn = reisebeginn
        this.reiseende = reiseende
        this.reisestart = reisestart instanceof Adresse ? reisestart : new Adresse(reisestart.strasse, reisestart.nr, reisestart.plz, reisestart.ort, reisestart.additionalName)
        this.reiseziel = reiseziel instanceof Adresse ? reiseziel : new Adresse(reiseziel.strasse, reiseziel.nr, reiseziel.plz, reiseziel.ort, reiseziel.additionalName)
        this.wegpunkte = Array.isArray(wegpunkte) ? wegpunkte.map(item => {
            return new Adresse(item.strasse, item.nr, item.plz, item.ort)
        }) : []
        this.routetype = routetype || 'Start/Ziel'
        this.fahrtkosten = fahrtkosten
        this.auslagen = new Tagegeld(auslagen?.active, auslagen?.tage, auslagen?.satz, auslagen?.summe, auslagen?.calculated)
        this.uebernachtungsgelder = uebernachtungsgelder
        this.nebenkosten = nebenkosten
        this.kostenstelle = kostenstelle
        this.kostentraeger = kostentraeger
        
        //Initiales Laden der Belege
        this.belege = []
        this.get_belege(false)

        this.printed = printed
    }

    get_belege(onSnap = true){
        const belegeRef = collection(getFirestore(), 'Abrechnungen/'+this.id+'/Belege')
        if(onSnap){
            const queryRef = query(belegeRef, where('url', '!=', 'Test'))
            this.belegeSnap = onSnapshot(queryRef, snap => {
                this.belege = []
                snap.forEach(beleg => {
                    this.belege.push(new Beleg(beleg.id, this.id, beleg.data().filename, beleg.data().name, beleg.data().beschreibung, beleg.data().url, beleg.data().type))
                })
            })
        }
        else {
            getDocs(belegeRef).then(snap => {
                this.belege = []
                snap.forEach(beleg => {
                    this.belege.push(new Beleg(beleg.id, this.id, beleg.data().filename, beleg.data().name, beleg.data().beschreibung, beleg.data().url, beleg.data().type))
                })
            })
        }
    }

    async setAbrechnender(abrechnender){
        if(abrechnender instanceof User){
            this.abrechnender = abrechnender
        }
        else {
            this.abrechnender = new User(
                abrechnender?.id, 
                '', 
                abrechnender?.vorname, 
                abrechnender?.nachname, 
                abrechnender?.email, 
                abrechnender?.funktionen, 
                abrechnender?.adresse, 
                abrechnender?.bank instanceof Bank ? new Bank(abrechnender.bank.phrase, abrechnender.bank.toFirestore()) : new Bank(abrechnender?.customFields?.phrase, abrechnender?.bank), 
                abrechnender?.bank_last_changed,
                '', 
                {
                    phrase: abrechnender?.customFields?.phrase
                },
                abrechnender?.kreditorennummer,
                [],
                '',
                '',
                [],
                abrechnender?.kfz_kennzeichen,
                [],
                false,
                false,
                abrechnender?.kreditorennummer_verband
            )

            if(!this.abrechnender.kreditorennummer){
                const userDoc = await getDoc(doc(getFirestore(), 'User/'+this.abrechnender.id))
                if(userDoc.exists()){
                    this.abrechnender.kreditorennummer = userDoc.data().kreditorennummer
                }
            }
        }
        
    }

    calcAuslagensumme(){
        this.auslagen.summe = (parseFloat(this.auslagen.tage) * parseFloat(this.auslagen.satz)).toFixed(2)
    }
    
    calcUebernachtungensumme(){
        this.uebernachtungsgelder.betrag = parseFloat(this.uebernachtungsgelder.betrag).toFixed(2)
        this.uebernachtungsgelder.summe = (parseFloat(this.uebernachtungsgelder.anzahl) * parseFloat(this.uebernachtungsgelder.betrag)).toFixed(2)
    }

    calcFahrtkostenPKWSumme(){
        this.fahrtkosten.pkw.summe = (parseFloat(this.fahrtkosten.pkw.kilometer) * parseFloat(this.kilometersatz)).toFixed(2)
    }

    getNebenkostenSumme(){
        var summe = 0
        this.nebenkosten.forEach(item => {
            summe += parseFloat(item.kosten)
        })
        return parseFloat(summe).toFixed(2) 
    }

    calculateTage(){
        var start, ende, dauer
        this.auslagen.calculated.tage = []
        if(this.reisebeginn.datum == this.reiseende.datum){
            this.auslagen.calculated.active = true
            start = this.reisebeginn.uhrzeit.split(':')
            ende = this.reiseende.uhrzeit.split(':')
            dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
            this.auslagen.calculated.tage.push(
                new TagegeldTag(this.reisebeginn.datum, dauer, 0)
            )
        }
        else {
            this.auslagen.calculated.active = true
            var startDate = new Date(this.reisebeginn.datum)
            var endDate = new Date(this.reiseende.datum)
            var date = new Date(this.reisebeginn.datum)
            while(date.getTime() <= endDate.getTime()){
                if(date.getTime() == startDate.getTime()){
                    start = this.reisebeginn.uhrzeit.split(':')
                    ende = '23:59'.split(':')
                    dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
                    this.auslagen.calculated.tage.push(
                        new TagegeldTag(this.reisebeginn.datum, dauer, 0)
                    )
                }
                else if(date.getTime() == endDate.getTime()){
                    start = '00:00'.split(':')
                    ende = this.reiseende.uhrzeit.split(':')
                    dauer = Math.round(parseInt(ende[0]) - parseInt(start[0])) + ((parseInt(ende[1]) - parseInt(start[1])) / 60)
                    this.auslagen.calculated.tage.push(
                        new TagegeldTag(this.reiseende.datum, dauer, 0)
                    )
                }
                else {
                    this.auslagen.calculated.tage.push(
                        new TagegeldTag(date.toJSON().split('T')[0], 24, 0)
                    )
                }
                date.setDate(date.getDate() + 1)
            }
        }
        this.auslagen.calculateSumme()
    }

    gesamtsumme(){
        var summe = 0
        if(this.fahrtkosten.pkw.active){
            summe += parseFloat(this.fahrtkosten.pkw.summe)
        }
        if(this.fahrtkosten.bundesbahn.active){
            summe += parseFloat(this.fahrtkosten.bundesbahn.summe)
        }
        if(this.fahrtkosten.zuschlaege.active){
            summe += parseFloat(this.fahrtkosten.zuschlaege.summe)
        }
        if(this.fahrtkosten.bus_taxi.active){
            summe += parseFloat(this.fahrtkosten.bus_taxi.summe)
        }
        if(this.fahrtkosten.flugzeug.active){
            summe += parseFloat(this.fahrtkosten.flugzeug.summe)
        }
        
        if(this.auslagen.active && this.auslagen.calculated.active){
            summe += parseFloat(this.auslagen.calculated.summe)
        }
        else if(this.auslagen.active && !this.auslagen.calculated.active){
            summe += parseFloat(this.auslagen.summe)
        }

        if(this.uebernachtungsgelder.active){
            summe += parseFloat(this.uebernachtungsgelder.summe)
        }
        summe += parseFloat(this.getNebenkostenSumme())

        return parseFloat(summe).toFixed(2)
    }

    toFirestore(){
        return {
            id: this.id,
            versionId: this.versionId,
            empfaenger: this.empfaenger,
            abrechnender: Object.assign({customFields: this.abrechnender.customFields}, this.abrechnender.toFirestore()),
            reisezweck: this.reisezweck,
            reise_angeordnet: this.reise_angeordnet,
            reisebeginn: this.reisebeginn,
            reiseende: this.reiseende,
            reisestart: this.reisestart.toFirestore(),
            reiseziel: this.reiseziel.toFirestore(),
            wegpunkte: this.wegpunkte.map(item => {
                return item.toFirestore()
            }),
            routetype: this.routetype,
            fahrtkosten: this.fahrtkosten,
            auslagen: this.auslagen.toFirestore(),
            uebernachtungsgelder: this.uebernachtungsgelder,
            nebenkosten: this.nebenkosten,
            freigaben: this.freigaben,
            type: this.type,
            status: this.status,
            historie: this.historie,
            anmerkungen: this.anmerkungen,
            kostenstelle: this.kostenstelle,
            kostentraeger: this.kostentraeger,
            printed: this.printed,
            kreditorennummer: this.kreditorennummer || '',
            tags: this.tags
        }
    }
}

export default Reisekostenabrechnung