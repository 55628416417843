import TagegeldTag from "./tagegeldTag"

class Tagegeld {
    
    constructor(
        active = false,
        tage = 0,
        satz = 0,
        summe = 0,
        calculated = {
            active: false,
            hauptamt: false,
            tage: []
        }
    ) {
        this.active = active
        this.tage = tage
        this.satz = satz
        this.summe = summe
        this.calculated = {
            active: calculated?.active || false,
            hauptamt: calculated?.hauptamt || false,
            tage: Array.isArray(calculated?.tage) ? calculated?.tage?.map(item => {
                return new TagegeldTag(item.datum, item.dauer, item.verpflegung, calculated?.hauptamt)
            }) : [],
            summe: 0
        }
        this.calculateSumme()
    }

    calculateSumme(){
        this.calculated.summe = 0
        if(this.calculated.active === true && this.active === true){
            this.calculated.tage.forEach(item => {
                const tagSumme = item.calculate(this.calculated.hauptamt)
                this.calculated.summe += tagSumme
            })
        }
    }

    toFirestore(){
        this.calculateSumme()
        return {
            active: this.active,
            tage: this.tage,
            satz: this.satz,
            summe: this.summe,
            calculated: {
                active: this.calculated.active,
                hauptamt: this.calculated.hauptamt,
                tage: this.calculated.tage.map(item => {
                    return item.toFirestore()
                })
            }
        }
    }
}

export default Tagegeld