import store from '../store'
import router from '../routes/index'
import '../firebaseConfig'
import { getAuth, signOut, onAuthStateChanged } from 'firebase/auth'

const auth = getAuth()

let snap

export function getUser() {
  return auth.currentUser
}
export function logout() {
  signOut(auth)
    .then(() => {
      store.dispatch('showLoggedOut')
    })
    .catch((error) => {
      // An error happened.
      console.log(error)
    })
}
export default { getUser, logout }

onAuthStateChanged(auth, async (user) => {
  let userdata
  if (user) {
    store.dispatch('showLoader')
    store.dispatch('fetchUser', user)
  } else {
    store.dispatch('fetchUser', user)
  }
  // minimal handling of mandatory steps in the login or sign up process
})
