<template>
    <v-navigation-drawer app floating width="300" v-model="navshow" temporary color="background" style="padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);"> 
        <v-row class="px-4 pt-5">
            <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">
                <v-row no-gutters justify="space-between" align-content="center">
                    <h2>Navigation</h2>
                    <v-btn v-if="$vuetify.breakpoint.mdAndDown" icon color="primary" @click="navshow = false">
                        <font-awesome-icon class="secondary--text" icon="fa-light fa-xmark" size="2x" />
                    </v-btn>
                </v-row>
            </v-col>
            <v-col cols="12" v-if="$vuetify.breakpoint.mdAndDown">
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row class="px-4" v-for="(nav, index) in navigation" :key="'nav-item'+index">
            <v-col cols="12" class="my-2">
                <v-card color="transparent" flat :to="nav.to" active-class="primary--text" :exact="nav.exact">
                    <v-row>
                        <v-col cols="auto" align-self="center">
                            <font-awesome-icon :icon="nav.icon" size="2x" />
                        </v-col>
                        <v-col align-self="center">
                            <h4 class="text-uppercase">{{nav.name}}</h4>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12">
                <v-divider></v-divider>
            </v-col>
        </v-row>
    </v-navigation-drawer>
</template>
<script>
import { mapGetters } from 'vuex'

export default {
  name: 'Sidebar-Navigation',
  data: () => ({
    show: true,
    navigation_items: [
        {
            name: 'Startseite',
            icon: 'fa-light fa-home',
            to: '/start',
            auth: false,
        },  
        {
            name: 'Dashboard',
            icon: 'fa-light fa-table-columns',
            to: '/dashboard',
            auth: true,
            rolle: [
                'Mitarbeiter',
                'Kreisvorsitzender',
                'Kreiskassierer',
                'Abteilungsleiter',
                'Finanzen',
                'Admin'
            ]
        },
        {
            name: 'Abrechnung erstellen',
            icon: 'fa-light fa-plus',
            to: '/abrechnung-erstellen',
            auth: true,
            rolle: [
                'Mitarbeiter',
                'Kreisvorsitzender',
                'Kreiskassierer',
                'Abteilungsleiter',
                'Finanzen',
                'Admin'
            ]
        },
        {
            name: 'Meine Abrechnungen',
            icon: 'fa-light fa-files',
            to: '/abrechnungen',
            auth: true,
            exact: true,
            rolle: [
                'Mitarbeiter',
                'Kreisvorsitzender',
                'Kreiskassierer',
                'Abteilungsleiter',
                'Finanzen',
                'Admin'
            ]
        },
        {
            name: 'Anwesenheitslisten',
            icon: 'fa-light fa-ballot-check',
            to: '/anwesenheitslisten',
            auth: true,
            exact: true,
            rolle: [
                'Mitarbeiter',
                'Kreisvorsitzender',
                'Kreiskassierer',
                'Abteilungsleiter',
                'Finanzen',
                'Admin'
            ]
        },
        // {
        //     name: 'Meine Sitzungen',
        //     icon: 'fa-light fa-calendar-star',
        //     to: '/meine-sitzungen',
        //     auth: true,
        //     rolle: [
        //         'Mitarbeiter',
        //         'Kreisvorsitzender',
        //         'Kreiskassierer',
        //         'Abteilungsleiter',
        //         'Finanzen',
        //         'Admin'
        //     ]
        // },
        {
            name: 'Kreis-Abrechnungen',
            icon: 'fa-light fa-box-archive',
            to: '/abrechnungen/kreis',
            auth: true,
            rolle: [
                'Kreis-Ausschuss',
                'Kreisvorsitzender',
                'Kreiskassierer'
            ]
        },
        {
            name: 'Kreis-Verwaltung',
            icon: 'fa-light fa-cogs',
            to: '/kreis/verwaltung',
            auth: true,
            rolle: [
                'Admin'
            ]
        },
        {
            name: 'Abteilungs-Abrechnungen',
            icon: 'fa-light fa-box-archive',
            to: '/abrechnungen/flvw-abteilung',
            auth: true,
            rolle: [
                'Abteilungsleiter'
            ]
        },
        {
            name: 'FLVW-Abrechnungen',
            icon: 'fa-light fa-box-archive',
            to: '/abrechnungen/flvw',
            auth: true,
            rolle: [
                'Finanzen'
            ]
        },
        // {
        //     name: 'Pauschalen',
        //     icon: 'fa-light fa-wallet',
        //     to: '/pauschalen',
        //     auth: true,
        //     rolle: [
        //         'Kreisvorsitzender',
        //         'Kreiskassierer',
        //         'Admin'
        //     ]
        // },
        {
            name: 'Abteilungen',
            icon: 'fa-light fa-wallet',
            to: '/admin/abteilungen',
            auth: true,
            rolle: [
                'Finanzen',
                'Admin'
            ]
        },
        {
            name: 'Benutzer-Verwaltung',
            icon: 'fa-light fa-user-group',
            to: '/admin/userverwaltung',
            auth: true,
            rolle: [
                'Finanzen',
                'Admin'
            ]
        },
    ]
  }),
  computed: {
    ...mapGetters({
      user: 'user',
    }),
    navigation(){
        if(this.user.data){
            return this.navigation_items.filter(item => 
            item.auth == true 
            && (item.rolle?.includes(this.user.data.rolle) 
                || item.rolle?.some(r => this.user.data?.rollen?.includes(r))
                || (item.rolle.includes('Kreis-Ausschuss') && this.user.data?.ausschuesse.length > 0))
            )
        }
        else {
            return this.navigation_items.filter(item => !item.auth)
        }
    }, 
    navshow: {
        get(){
            return this.value
        },
        set(val){
            this.$emit('input', val)
        }
    }
  },
  props: {
    value: {
        type: Boolean,
        required: true
    }
  },
  watch: {
    value() {
        this.$emit('input', this.value)
    }
  },
};
</script>