class Uebernachtung {
    constructor(
        kosten_pro_nacht,
        anz_naechte = 1,
        belege = [],
        summe = 0
    ) {
        this.kosten_pro_nacht = kosten_pro_nacht
        this.anz_naechte = anz_naechte
        this.summe = this.calculateSumme()
        this.belege = belege
    }

    calculateSumme() {
        if(this.kosten_pro_nacht > 0 && this.anz_naechte > 0){
            return this.anz_naechte * this.kosten_pro_nacht
        }
        return 0
    }

    toFirestore() {
        return {
            kosten_pro_nacht: this.kosten_pro_nacht,
            anz_naechte: this.anz_naechte,
            belege: this.belege,
            summe: this.calculateSumme()
        }
    }
}

export default Uebernachtung