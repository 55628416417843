import { getAuth, signInAnonymously } from 'firebase/auth'
import { getFirestore, doc, collection, where, onSnapshot, query, getDocs } from '@firebase/firestore'
import store from '@/store'
import userConverter from '@/classes/User/userConverter'
import router from '@/routes'
import abrechnungConverter from '@/classes/Abrechnung/abrechnungConverter'
import md5 from 'md5'

const auth = getAuth()
const db = getFirestore()

const UserModule = {
  state: {
    user: {
      loggedIn: false,
      loader: true,
      ms: false,
      loggedOut: false,
      data: null,
    },
    abrechnungen: {
      eigene: [],
      kreis_alle: [],
      abteilung_alle: [],
      finanzen_alle: [],
      KreisSnap: '',
      KKSnap: '',

    },
    kostenstellen: {
      kreis: [],
      verband: []
    },
    kostentraeger: [],
    loader: {
      active: true
    },
    open_search: false,
    vorlagen: []
  },
  getters: {
    user(state) {
      return state.user
    },
    userdaten(state){
      return state.user.data
    },
    abrechnungen(state) {
      return state.abrechnungen
    },
    loader(state) {
      return state.loader
    },
    kostenstellen(state) {
      return state.kostenstellen
    },
    kostentraeger(state) {
      return state.kostentraeger
    },
    vorlagen(state) {
      return state.vorlagen
    },
    open_search(state) {
      return state.open_search
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.user.loggedIn = value
    },
    SET_LOGGED_OUT(state, value) {
      state.user.loggedOut = value
    },
    SET_LOADING(state, value) {
      state.loader.active = value
    },
    async SET_USER(state, data) {
      state.user.data = data
      state.loader.active = false
      if(!data || data === null){
        return
      }
      state.user.ms = data.providerData?.find(p => p.providerId === 'microsoft.com') ? true : false
      const abrechnungenDocs = query(collection(getFirestore(), 'Abrechnungen'), where('abrechnender.id', '==', data.id)).withConverter(abrechnungConverter)
      const abrechnungSnap = onSnapshot(abrechnungenDocs, (abrechnungen) => {
        state.abrechnungen.eigene = []
        abrechnungen.forEach(abrechnung => {
          var daten = abrechnung.data()
          state.abrechnungen.eigene.push(daten)
        })
      })
      
      const vorlagenDocs = query(collection(getFirestore(), 'User/'+data.id+'/Vorlagen'), where('abrechnender.id', '==', data.id)).withConverter(abrechnungConverter)
      const vorlagenSnap = onSnapshot(vorlagenDocs, (vorlagen) => {
        state.vorlagen = []
        vorlagen.forEach(vorlage => {
          var daten = vorlage.data()
          state.vorlagen.push(daten)
        })
      })
      await data.checkRollen()
      if(data.kv() || data.kk() || data.ka()){
        const kreise = data.ausschuesse.map(item => { return item.kreisId })
        const ausschuss = data.ausschuesse.map(item => { return item.ausschussId })

        var q = query(collection(getFirestore(), 'Abrechnungen'),
          where('empfaenger.refId', '==', parseInt(data.customFields.kreis))
        )

        if(!data.kv() && !data.kk() && data.ka()){
          if(kreise.length > 0 || ausschuss.length > 0) {
            q = query(collection(getFirestore(), 'Abrechnungen'),
              where('empfaenger.refId', 'in', kreise)
            )
          }
          
        }
      }

      state.kostenstellen.kreis = []
      const kreiskostenstellenDocs = collection(getFirestore(), 'Kreiskostenstellen')
      const kreiskostenstellen = await getDocs(kreiskostenstellenDocs)
      kreiskostenstellen.forEach(item => {
        state.kostenstellen.kreis.push(Object.assign({}, item.data()))
      })
      state.kostenstellen.verband = []
      const kostenstellenDocs = collection(getFirestore(), 'Kostenstellen')
      const kostenstellen = await getDocs(kostenstellenDocs)
      kostenstellen.forEach(item => {
        state.kostenstellen.verband.push(Object.assign({}, item.data()))
      })
      state.kostentraeger = []
        const kostentraegerDocs = collection(getFirestore(), 'Kostentraeger')
        const kostentraeger = await getDocs(kostentraegerDocs)
        kostentraeger.forEach(item => {
          state.kostentraeger.push(Object.assign({}, item.data()))
      })
    },
  },
  actions: {
    fetchUser({ commit }, user) {
      commit('SET_LOGGED_IN', user !== null)
        
      if (user) {
        var photoUrl = '/icons/SVG/user.svg'
        var email = user.email
        var name = []

        if(user.displayName){
          name = user.displayName.split(', ')
        }

        const userDoc = doc(db, 'User/' + user.uid).withConverter(userConverter)
        
        const snap = onSnapshot(userDoc, (userdoc) => {
          if (userdoc.exists()) {
            user = userdoc.data()
            user.photoUrl = photoUrl
            user.email = email
            commit('SET_USER', user)
            if(user.customFields.fill){
              router.push('/profil-vervollstaendigen').catch(error => {
                if(error.name != "NavigationDuplicated"){
                  console.log(error)
                  throw error
                }
              })
            }
            return
          }
        })

        
      } else {
        commit('SET_USER', null)
      }
    },
    showLoggedOut({ commit }) {
      router.push('/login').catch(error => {
        if(error.name != "NavigationDuplicated"){
          console.log(error)
          throw error
        }
      })
      commit('SET_LOGGED_OUT', true)
    },
    isLoggedIn() {
      return this.state.user.loggedIn
    },
    isLoggedOut() {
      return this.state.user.loggedOut
    },
    showLoader({ commit }) {
      commit('SET_LOADING', true)
    },
    hideLoader({ commit }) {
      commit('SET_LOADING', false)
    },
  },
}

export default UserModule
