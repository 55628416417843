class TagegeldTag {

    constructor(
       datum,
       dauer,
       verpflegung,
       hauptamt
    ) {
        this.datum = datum
        this.dauer = Math.floor(dauer)
        this.verpflegung = verpflegung
        this.hauptamt = hauptamt ?? false
    }

    calculate(hauptamt){
        var summe = 0
        if(hauptamt || this.hauptamt){
            if(this.dauer < 8){
                summe = 0
            }
            else if(this.dauer >= 8 && this.dauer < 24){
                summe = 14
            }
            else if(this.dauer >= 24){
                summe = 28
            }
        }
        else {
            if(this.dauer < 8){
                summe = 8
            }
            else if(this.dauer >= 8){
                summe = 14
            }
        }
        return this.calcVerpflegung(summe)
    }

    /**
     * Verpflegungsarten:
     * 0 = keine -> Voller Tagessatz
     * 1 = Frühstück -> -5,60€
     * 2 = Mittagessen -> -11,20€
     * 3 = Abendessen -> -11,20€
     * 4 = Frühstück und Mittagessen -> -16,80€
     * 5 = Frühstück und Abendessen -> -16,80€
     * 6 = Mittagessen und Abendessen -> -22,40€
     * 7 = Frühstück, Mittagessen und Abendessen -> -28,00€
     * 8 = Verpflegung (Ehrenamt) -> -14,00€
     */
    calcVerpflegung(summe){
        if(this.verpflegung == 1){
            summe -= 5.6
        }
        else if(this.verpflegung == 2){
            summe -= 11.2
        }
        else if(this.verpflegung == 3){
            summe -= 11.2
        }
        else if(this.verpflegung == 4){
            summe -= 16.8
        }
        else if(this.verpflegung == 5){
            summe -= 16.8
        }
        else if(this.verpflegung == 6){
            summe -= 22.4
        }
        else if(this.verpflegung == 7){
            summe -= 28
        }
        else if(this.verpflegung == 8){
            summe = 0
        }

        if(summe < 0){
            summe = 0
        }

        return summe
    }

    getVerpflegung(){
        if(this.verpflegung == 1){
            return "Frühstück"
        }
        else if(this.verpflegung == 2){
            return "Mittagessen"
        }
        else if(this.verpflegung == 3){
            return "Abendessen"
        }
        else if(this.verpflegung == 4){
            return "Frühstück und Mittagessen"
        }
        else if(this.verpflegung == 5){
            return "Frühstück und Abendessen"
        }
        else if(this.verpflegung == 6){
            return "Mittagessen und Abendessen"
        }
        else if(this.verpflegung == 7){
            return "Frühstück, Mittagessen und Abendessen"
        }
        else if(this.verpflegung == 8){
            return "Verpflegung (Ehrenamt)"
        }
        else {
            return "Keine Verpflegung"
        }
    }


    toFirestore(){
        return {
            datum: this.datum,
            dauer: this.dauer,
            verpflegung: this.verpflegung
        }
    }
}

export default TagegeldTag