import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import de from "vuetify/lib/locale/de";

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import specific icons */
import { fas } from '@fortawesome/pro-solid-svg-icons'
import { far } from '@fortawesome/pro-regular-svg-icons'
import { fal } from '@fortawesome/pro-light-svg-icons'
import { fad } from '@fortawesome/pro-duotone-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome'
import { VBtn, VCard, VDialog, VTextField } from "vuetify/lib";

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('font-awesome-layers', FontAwesomeLayers)

/* add icons to the library */
library.add(fas, far, fal, fad, fab)

Vue.use(Vuetify);

VTextField.options.props.filled.default = true
VTextField.options.props.rounded.default = true
VTextField.options.props.hideDetails.default = 'auto'

VBtn.options.props.rounded.default = true

VDialog.options.props.contentClass.default = 'rounded-xl'
VDialog.options.props.overlayOpacity.default = 0.85
VDialog.options.props.persistent.default = true

export default new Vuetify({
    theme: {
        themes: {
            light: {
                primary: '#d91622',
                secondary: '#989CA0',
                accent: '#ec671a',
                error: '#9b2335',
                info: '#29bbdf',
                success: '#00933b',
                warning: '#ffd000',
                black: '#111210',
                deepblue: '#3b4395',
                accent2: '#e83067',
                lightgreen: '#bbcf21',
                background: '#ffffff'
            },
            dark: {
                primary: '#d91622',
                secondary: '#989CA0',
                accent: '#ec671a',
                error: '#9b2335',
                info: '#29bbdf',
                success: '#00933b',
                warning: '#ffd000',
                black: '#111210',
                deepblue: '#3b4395',
                accent2: '#e83067',
                lightgreen: '#bbcf21',
                background: '#111210',
            },
        }
    }
});
