import TagegeldTag from "./tagegeldTag"

class Tagegeld {

    tagessaetze = [
        {
            name: 'Bis 8 Std. (8,00€)',
            value: 8.00
        },
        {
            name: 'Über 8 Std. (14,00€)',
            value: 14.00
        },
    ]
    
    hauptamtVerpflegung = [
        {
            name: 'Keine Verpflegung',
            value: 0
        },
        {
            name: 'Frühstück',
            value: 1,
        },
        {
            name: 'Mittagessen',
            value: 2,
        },
        {
            name: 'Abendessen',
            value: 3,
        },
        {
            name: 'Frühstück & Mittagessen',
            value: 4,
        },
        {
            name: 'Frühstück & Abendessen',
            value: 5,
        },
        {
            name: 'Mittagessen & Abendessen',
            value: 6,
        },
        {
            name: 'Frühstück, Mittagessen & Abendessen',
            value: 7,
        }
    ]

    ehrenamtVerpflegung = [
        {
            name: 'Keine Verpflegung',
            value: 0
        },
        {
            name: 'Min. eine warme Mahlzeit',
            value: 8,
        },
    ]

    constructor(
        abwesend_von,
        abwesend_bis,
        tagegeld,
        calculated = {
            active: false,
            hauptamt: false,
            tage: []
        }
    ) {
        this.abwesend_von = abwesend_von ? new Date(abwesend_von) : ''
        this.abwesend_bis = abwesend_bis ? new Date(abwesend_bis) : ''
        
        if(typeof tagegeld !== 'undefined'){
            this.tagegeld = tagegeld
        }
        else {
            this.tagegeld = this.abwesend_von instanceof Date && this.abwesend_bis instanceof Date ? true : false
        }

        this.calculated = {
            active: calculated?.active || false,
            hauptamt: calculated?.hauptamt || false,
            tage: Array.isArray(calculated?.tage) ? calculated?.tage?.map(item => {
                return new TagegeldTag(item.datum, item.dauer, item.verpflegung, item.summe)
            }) : [],
            summe: 0
        }
        this.calculateSumme()
    }

    calculateSumme(){
        //New calculation
        this.calculated.summe = 0
        this.calculated.tage.forEach(item => {
            this.calculated.summe += item.calculate(this.calculated.hauptamt)
        })

        if(this.calculated.active === true && this.tagegeld === true){
            this.summe = this.calculated.summe
            return
        }
        else if(this.tagegeld === true && this.abwesend_von instanceof Date && this.abwesend_bis instanceof Date){
            this.difference = (((this.abwesend_bis.getTime() - this.abwesend_von.getTime()) / 1000) / 60) / 60;
            
            if(this.difference < 8){
                this.summe = 8
                return 
            }
            else if(this.difference >= 8){
                this.summe = 14
                return
            }
        }
        else {
            this.calculated.summe = 0
            this.summe = 0
        }
    }

    get_abwesend_von(){
        if(!this.abwesend_von){
            return ''
        }
        return this.abwesend_von.getHours().toString().padStart(2, '0')
        + ':' + this.abwesend_von.getMinutes().toString().padStart(2, '0')
    }
    
    get_abwesend_bis(){
        if(!this.abwesend_bis){
            return ''
        }
        return this.abwesend_bis.getHours().toString().padStart(2, '0')
        + ':' + this.abwesend_bis.getMinutes().toString().padStart(2, '0')
    }


    toFirestore(){
        //this.calculateSumme()
        return {
            abwesend_von: this.abwesend_von instanceof Date ? this.abwesend_von.toJSON() : '',
            abwesend_bis: this.abwesend_bis instanceof Date ? this.abwesend_bis.toJSON() : '',
            summe: this.summe,
            tagegeld: this.tagegeld,
            calculated: {
                active: this.calculated.active,
                hauptamt: this.calculated.hauptamt,
                tage: this.calculated.tage.map(item => {
                    return item.toFirestore()
                })
            }
        }
    }
}

export default Tagegeld