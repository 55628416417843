const Openiban = require('openiban')
const c = require('crypto-js')

export async function checkIban(iban){
    return Openiban.validate(iban)
    .then(result => {
        if(result.valid === true){
            return {
                valid: true,
                iban: result.iban,
                bic: result.bankData.bic,
                bankname: result.bankData.name
            }
        }
        return {
            valid: false,
            iban: iban,
            bic: '',
            bankname: ''
        }
    })
}

class Bank {
    constructor(
        key,
        data
    ) {
        this.phrase = key
        this.setData(data)
    }

    encrypt(){
        if(this.iban && this.bic){
            return {
                iban: c.AES.encrypt(this.iban, this.phrase),
                bic: c.AES.encrypt(this.bic, this.phrase),
                bankname: c.AES.encrypt(this.bankname, this.phrase)
            }
        }
        return ''
    }

    getIbanLast4Digits(){
        return '-'+this.iban.substr(this.iban.length - 4)
    }

    decrypt(str){
        try{
            const bytes = c.AES.decrypt(str, this.phrase);
            const daten = bytes.toString(c.enc.Utf8);
            return daten;
        }
        catch(e){
            return ''
        }
    }

    async setData(daten){
        if(daten?.iban && daten?.bic){
            this.iban = this.decrypt(daten.iban)
            this.bic = this.decrypt(daten.bic)
            this.bankname = this.decrypt(daten.bankname)
            return
        }
        else if(daten?.iban){
            const data = await checkIban(daten.iban)
            if(data.valid){
                this.iban = data.iban
                this.bic = data.bic
                this.bankname = data.bankname
                return
            }
        }
        this.iban = ''
        this.bic = ''
        this.bankname = ''
    }

    toFirestore(){
        const data = this.encrypt()
        return Object.assign({}, {
            iban: ''+data.iban,
            bic: ''+data.bic,
            bankname: ''+data.bankname
        })
    }
}

export default Bank