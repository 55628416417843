import Kreis from "./kreis"

class KreisCollection {
    constructor(
        kreise = []
    ) {
        this.kreise = []
        kreise.forEach(kreis => {
            if(kreis instanceof Kreis){
                this.kreise.push(kreis)
            }
            else if(kreis instanceof Object && kreis.nummer && kreis.name && kreis.kurz && kreis.bereiche){
                this.kreise.push(new Kreis(kreis.nummer, kreis.name, kreis.kurz, kreis.bereiche))
            }
        })

        this.sortiert = () => {
            return this.kreise.sort((a,b) => a.name > b.name ? 1 : -1)
        }

        this.length = () => {
            return this.kreise.length
        }
    }

    sucheKreis(id){
        return this.kreise.find(kreis => kreis.id == id) || new Kreis()
    }

    

    hinzufuegen(kreis){
        if(kreis instanceof Kreis){
            this.kreise.push(kreis)
        }
        else if(kreis instanceof Object && kreis.nummer && kreis.name && kreis.kurz && kreis.bereiche){
            this.kreise.push(new Kreis(kreis.nummer, kreis.name, kreis.kurz && kreis.bereiche))
        }
        else if(Array.isArray(kreis)){
            kreis.forEach(k => {
                if(k instanceof Kreis){
                    this.kreise.push(k)
                }
                else if(k instanceof Object && k.nummer && k.name && k.kurz && k.bereiche){
                    this.kreise.push(new Kreis(k.nummer, k.name, k.kurz, k.bereiche))
                }
            })
        }
        this.kreise.sort((a,b) => a.name > b.name ? 1 : -1)
    }
}

export default KreisCollection