class Fahrtkosten {
    constructor(
        pkw = 0,
        bahn = 0,
        flugzeug = 0,
        zuschlaege = 0,
        taxi = 0,
        belege = []
    ) {
        this.pkw = pkw
        this.bahn = bahn
        this.flugzeug = flugzeug
        this.zuschlaege = zuschlaege
        this.taxi = taxi
        this.belege = belege
        this.summe = this.calculateSumme()
    }

    calculateSumme() {
        return this.pkw + this.bahn + this.flugzeug + this.zuschlaege + this.taxi
    }

    toFirestore() {
        return {
            pkw: this.pkw,
            bahn: this.bahn,
            flugzeug: this.flugzeug,
            zuschlaege: this.zuschlaege,
            taxi: this.taxi,
            belege: this.belege,
            summe: this.calculateSumme()
        }
    }
}

export default Fahrtkosten