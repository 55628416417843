<template>
    <v-list-item @click="select()">  
        <v-list-item-avatar v-if="selectmode">
            <font-awesome-icon :icon="['fal', 'circle-check']" v-if="selected" />
            <font-awesome-icon :icon="['fal', 'circle']" v-else />
        </v-list-item-avatar>                              
        <v-list-item-content v-if="abrechnung.type == 'Auslagenabrechnung'">  
            <v-list-item-title v-if="$vuetify.breakpoint.mdAndUp">{{abrechnung.type}} - <strong class="primary--text">{{ abrechnung.zeitraum.value.name }}</strong></v-list-item-title>
            <v-list-item-subtitle v-if="$vuetify.breakpoint.smAndDown">{{abrechnung.type}}</v-list-item-subtitle>
            <v-list-item-title v-if="$vuetify.breakpoint.smAndDown"><strong class="primary--text">{{ abrechnung.zeitraum.value.name }}</strong></v-list-item-title>
            <v-list-item-subtitle><span class="font-weight-bold" v-if="!own">{{ abrechnung.abrechnender.vorname }} {{ abrechnung.abrechnender.nachname }} | </span>{{ abrechnung.empfaenger.type == 'Kreis-Ausschuss' ? abrechnung.empfaenger.ausschuss.name+' - ' : abrechnung.empfaenger.type.includes('Landesverband') ? 'FLVW ' : '' }}{{ abrechnung.empfaenger.name }}<small class="ml-2">(Summe: <strong>{{abrechnung.gesamtsumme()}}€</strong>)</small></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-content v-else>
            <v-list-item-title v-if="$vuetify.breakpoint.mdAndUp">{{abrechnung.type}} - <strong class="primary--text">{{ abrechnung.reisezweck }} ({{ daten(abrechnung.reisebeginn.datum, abrechnung.reiseende.datum) }})</strong></v-list-item-title>
            <v-list-item-subtitle v-if="$vuetify.breakpoint.smAndDown">{{abrechnung.type}}</v-list-item-subtitle>
            <v-list-item-title v-if="$vuetify.breakpoint.smAndDown"><strong class="primary--text">{{ abrechnung.reisezweck }} ({{ daten(abrechnung.reisebeginn.datum, abrechnung.reiseende.datum) }})</strong></v-list-item-title>
            <v-list-item-subtitle><span class="font-weight-bold" v-if="!own">{{ abrechnung.abrechnender.vorname }} {{ abrechnung.abrechnender.nachname }} | </span> {{ abrechnung.empfaenger.type == 'Kreis-Ausschuss' ? abrechnung.empfaenger.ausschuss.name+' - ' : abrechnung.empfaenger.type.includes('Landesverband') ? 'FLVW ' : '' }}{{ abrechnung.empfaenger.name }}<small class="ml-2">(Summe: <strong>{{abrechnung.gesamtsumme()}}€</strong>)</small></v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action class="text-center">
            <v-chip color="primary"><font-awesome-icon :icon="['fal', 'print']" v-if="abrechnung.printed" class="mr-2" />{{abrechnung.status == 'Neu' ? 'Entwurf' : abrechnung.status == 'Offen' ? 'Genehmigung offen' : abrechnung.status}}</v-chip>
        </v-list-item-action>
    </v-list-item>
</template>

<script>
import Abrechnung from '@/classes/Abrechnung/abrechnung';

    export default {
        name: 'Abrechnung',
        data(){
            return {
                selected: false
            }
        },
        props: {
            abrechnung: {
                required: true,
                type: Abrechnung
            },
            toPrefix: {
                default: '/abrechnungen/',
                type: String
            },
            disabled: {
                default: false,
                type: Boolean
            },
            own: {
                default: false,
                type: Boolean
            },
            selectmode: {
                default: false,
                type: Boolean
            }
        },
        watch: {
            selectmode() {
                this.selected = false
            }
        },
        methods: {
            select(){
                if(this.selectmode){
                    this.selected = !this.selected
                    this.$emit('selected', { selected: this.selected, abrechnung: this.abrechnung.id })
                }
                else {
                    this.$router.push(`${this.toPrefix}${this.abrechnung.id}`)
                }
            },
            daten(val1, val2){
                if(val1 && val2){
                    const datum = val1.split('-')
                    const datum2 = val2.split('-')
                    if(val1 == val2){
                        return datum[2]+'.'+datum[1]+'.'+datum[0]
                    }
                    else {
                        if(datum[1] == datum2[1] && datum[0] == datum2[0]){
                            return datum[2]+'. - '+datum2[2]+'.'+datum[1]+'.'+datum[0]
                        }
                        else if(datum[0] == datum2[0]){
                            return datum[2]+'.'+datum[1]+'. - '+datum2[2]+'.'+datum2[1]+'.'+datum[0]
                        }
                        return datum[2]+'.'+datum[1]+'.'+datum[0]+' - '+datum2[2]+'.'+datum2[1]+'.'+datum2[0]
                    }
                }
                return ''
            },
        }
    }
</script>