import User from "./user";

const userConverter = {
    toFirestore: (user) => {
        return user.toFirestore()
    },
    fromFirestore: (snapshot, options) => {
        const data = snapshot.data(options);
        return new User(
            snapshot.id,
            data.rolle,
            data.vorname,
            data.nachname,
            data.email,
            data.funktionen,
            data.adresse,
            data.bank,
            data.bank_last_changed,
            data.photoUrl,
            Object.assign({}, {
                fill: data.fill,
                phrase: data.k,
                kreis: data.kreis,
                abteilung: data.abteilung
            }),
            data.kreditorennummer,
            data.rollen,
            data.signatur,
            data.benachrichtigungen,
            data.meine_sitzungen,
            data.kfz_kennzeichen,
            data.haeufige_orte,
            data.darkmode,
            data.fill,
            data.kreditorennummer_verband,
            data.favoriten_orte
        );
    }
}

export default userConverter