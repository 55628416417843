class Funktion {
    constructor(
        ebene,
        gebiet,
        abteilung,
        funktion,
        freitext,
        readonly
    ) {
        this.ebene = ebene
        this.gebiet = gebiet
        this.abteilung = abteilung
        this.funktion = funktion
        this.freitext = freitext
        this.readonly = readonly || false
    }

    toFirestore(){
        return {
            ebene: this.ebene || '',
            gebiet: this.gebiet || '',
            abteilung: this.abteilung || '',
            funktion: this.funktion || '',
            freitext: this.freitext || '',
            readonly: this.readonly || false,
        }
    }
}

export default Funktion