<template>
  <v-app style="padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);">
    <Header v-model="show" />
    <sidebarNavigationVue v-model="show" />
    <v-main :style="`background-color: ${$vuetify.theme.currentTheme.background};`">
      <div v-if="!loader.active">
        <router-view stlye="width: 100vw;"></router-view>
      </div>
      <v-fade-transition>
        <Loader v-if="loader.active" />
      </v-fade-transition>
    </v-main>
    <v-footer color="transparent">
      <v-chip color="primary" v-if="isREFZ" class="font-weight-bold"><font-awesome-icon icon="fa-light fa-code" size="lg" class="mr-2" />TEST</v-chip>
      <v-btn text color="primary" small class="ma-2 font-weight-bold" rounded to="/changelog">Version {{ version }}</v-btn> 
      <v-spacer v-if="$vuetify.breakpoint.mdAndUp"></v-spacer>
      <v-btn text small class="ma-2" rounded to="/datenschutz">Datenschutz</v-btn>
      <v-btn text small class="ma-2" rounded to="/impressum">Impressum</v-btn>
      <v-btn text small class="ma-2" rounded to="/support">Hilfe / Fehler melden</v-btn>
    </v-footer>
  </v-app>
</template>

<script>
import Header from '@/components/header.vue'
import sidebarNavigationVue from './components/navigation/sidebar-navigation.vue';
import Loader from './components/loader/loader'
import { mapGetters } from 'vuex'

export default {
  name: 'App',
  computed: {
    ...mapGetters({
      user: 'user',
      loader: 'loader'
    }),
  },
  components: {
    Loader,
    Header,
    sidebarNavigationVue
  },
  created(){
    if(sessionStorage.getItem('darkmode') != null){
      this.$vuetify.theme.dark = sessionStorage.getItem('darkmode') == 'true' ? true : false 
    }
    else {
      this.$vuetify.theme.dark = window.matchMedia("(prefers-color-scheme:dark)").matches
    }
    this.version = process.env.VUE_APP_VERSION
    this.isREFZ = process.env.VUE_APP_FIREBASE_PROJECT_ID === 'flvw-app-refz'
  },
  data: () => ({
    show: false,
    version: '',
    isREFZ: false
  }),
};
</script>

<style>
  /* Regular */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/font/SegoeUI.woff2') format('woff2'), local('Segoe UI'), local('Segoe UI');
  }
  /* Semibold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 600;
    src: url('./assets/font/SegoeUI-Semibold.woff2') format('woff2'), local('Segoe UI'), local('Segoe UI');
  }
  /* Bold */
  @font-face {
    font-family: 'Segoe UI';
    font-style: normal;
    font-weight: 800;
    src: url('./assets/font/SegoeUI-Bold.woff2') format('woff2'), local('Segoe UI'), local('Segoe UI');
  }

  *::-webkit-scrollbar {
    display: none;
  }

  body, .v-application {
    font-family: 'Segoe UI' !important;
    color: #272a35;
    scrollbar-width: none;
  }

  html {
    overflow: -moz-scrollbars-none;
    scrollbar-color: transparent transparent;
  }

  h2.secondary-title {
    color: #989CA0;
    font-weight: 600;
    margin-bottom: 15px;
  }

  .flvw-card-red {
    background: #d91622;
    background: -moz-linear-gradient(-45deg,#d91622 0,#9d000a 100%);
    background: -webkit-linear-gradient(-45deg,#d91622 0,#9d000a 100%);
    background: linear-gradient(135deg,#d91622 0,#9d000a 100%);
  }

  .bg-primary-gradient {
    color: #ffffff;
    background: #811117;
    background: -moz-linear-gradient(left,rgba(129,17,23,1) 0,rgba(217,22,34,1) 100%);
    background: -webkit-linear-gradient(left,rgba(129,17,23,1) 0,rgba(217,22,34,1) 100%);
    background: linear-gradient(to right,rgba(129,17,23,1) 0,rgba(217,22,34,1) 100%);
  }

  .bg-primary-dark-gradient {
    background: #480a0d;
    background: -moz-linear-gradient(left,#480a0d 0,#811117 100%);
    background: -webkit-linear-gradient(left,#480a0d 0,#811117 100%);
    background: linear-gradient(to right,#480a0d 0,#811117 100%);
  }

  .bg-secondary-gradient {
    color: #fff;
    background: #676767;
    background: -moz-linear-gradient(left,#676767 0,#b2b2b2 100%);
    background: -webkit-linear-gradient(left,#676767 0,#b2b2b2 100%);
    background: linear-gradient(to right,#676767 0,#b2b2b2 100%);
  }
  
  .bg-secondary-dark-gradient {
    background: #4d4d4d;
    background: -moz-linear-gradient(left,#4d4d4d 0,#676767 100%);
    background: -webkit-linear-gradient(left,#4d4d4d 0,#676767 100%);
    background: linear-gradient(to right,#4d4d4d 0,#676767 100%);
  }

  .v-input__slot .v-progress-linear {
    left: 20px;
    width: calc(100% - 40px);
    top: calc(100% - 3px);
  }

  .v-dialog--fullscreen {
    padding: env(safe-area-inset-top) env(safe-area-inset-right) 0 env(safe-area-inset-left);
  }
</style>
