const c = require('crypto-js')
import md5 from 'md5'

class Person {
    constructor(
        uid, 
        vorname,
        nachname,
        email,
        adresse,
        iban,
        bic,
        bank
    ) {
        this.uid = uid
        this.vorname = vorname
        this.nachname = nachname
        this.email = email
        this.adresse = adresse
        this.iban = iban
        this.phrase = md5(this.uid+this.$client)
        this.bic = bic
        this.bank = bank
    }

    setIban(iban){
        this.iban = c.AES.encrypt(iban, this.phrase)
    }

    getIbanLast4Digits(){
        var iban = this.getIban()
        return '-'+iban.substr(iban.length - 4)
    }

    getIban(){
        const bytes = c.AES.decrypt(this.iban, this.phrase);
        const iban = bytes.toString(c.enc.Utf8);
        return iban;
    }
}

export default Person